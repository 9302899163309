var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "single-mini-preview",
      style: {
        height: _vm.height ? `${_vm.height}px` : "100%",
      },
    },
    [
      _c(
        "div",
        {
          staticClass: "graph-border",
          style: {
            backgroundRepeat: "no-repeat",
            backgroundImage:
              "url(" +
              require(`@/assets/images/lego/home_background_${_vm.pageConfigs.theme}.png`) +
              ")",
          },
        },
        [
          _c("div", { staticClass: "mini-name-line" }, [
            _c("i", { staticClass: "el-icon-location-outline" }),
            _c("span", { staticClass: "name-span" }, [
              _vm._v(
                "\n        " +
                  _vm._s(_vm.miniResource.miniprogramTitle) +
                  "\n      "
              ),
            ]),
            _c("i", { staticClass: "el-icon-arrow-down" }),
          ]),
          _c("img", {
            attrs: {
              src: require("@/assets/images/lego/home_head_img.png"),
              alt: "",
            },
          }),
          _c(
            "div",
            {
              staticClass: "items-container",
              style: {
                height: _vm.height
                  ? `calc(${_vm.height}px - 176px)`
                  : "calc(100% - 176px)",
              },
            },
            _vm._l(_vm.pageConfigs[_vm.currentPage], function (item) {
              return _c(
                "div",
                { key: item.id, staticClass: "single-item" },
                [
                  _c(item.key, {
                    key: item.id,
                    ref: "graphSingleRef",
                    refInFor: true,
                    tag: "component",
                    attrs: {
                      dataset: item,
                      theme: _vm.pageConfigs.theme,
                      currentPage: _vm.currentPage,
                      source: "preview",
                    },
                  }),
                ],
                1
              )
            }),
            0
          ),
          _c("MenuBar", {
            attrs: { theme: _vm.pageConfigs.theme },
            on: { pageChange: _vm.pageChangeHandler },
          }),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }