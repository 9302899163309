<template>
  <div
    class="single-mini-preview"
    :style="{
      height: height ? `${height}px` : '100%',
    }"
  >
    <div
      class="graph-border"
      :style="{
        backgroundRepeat: 'no-repeat',
        backgroundImage:
          'url(' +
          require(`@/assets/images/lego/home_background_${pageConfigs.theme}.png`) +
          ')',
      }"
    >
      <div class="mini-name-line">
        <i class="el-icon-location-outline"></i>
        <span class="name-span">
          {{ miniResource.miniprogramTitle }}
        </span>
        <i class="el-icon-arrow-down"></i>
      </div>
      <img src="@/assets/images/lego/home_head_img.png" alt="" />
      <div
        class="items-container"
        :style="{
          height: height ? `calc(${height}px - 176px)` : 'calc(100% - 176px)',
        }"
      >
        <div
          class="single-item"
          v-for="item in pageConfigs[currentPage]"
          :key="item.id"
        >
          <component
            :is="item.key"
            :key="item.id"
            :dataset="item"
            :theme="pageConfigs.theme"
            :currentPage="currentPage"
            source="preview"
            ref="graphSingleRef"
          ></component>
        </div>
      </div>
      <MenuBar :theme="pageConfigs.theme" @pageChange="pageChangeHandler" />
    </div>
  </div>
</template>

<script>
import MenuBar from "../../edit/components/menuBar.vue";
import banner from "../../edit/graphComponents/banner.vue";
import notify from "../../edit/graphComponents/notify.vue";
import news from "../../edit/graphComponents/news.vue";
import functions from "../../edit/graphComponents/functions.vue";
import services3 from "../../edit/graphComponents/services3.vue";
import services4 from "../../edit/graphComponents/services4.vue";

export default {
  name: "single-mini-preview",
  components: {
    MenuBar,
    banner,
    notify,
    news,
    functions,
    services3,
    services4,
  },
  props: {
    dataset: {
      type: Object,
      default() {
        return {};
      },
    },
    height: {
      type: Number,
      default() {
        return 0;
      },
    },
  },
  data() {
    return {
      miniResource: {},
      currentPage: "home",
      pageConfigs: {
        home: [],
        more: [],
        theme: "blue",
      },
    };
  },
  created() {
    this.initDatas();
  },
  methods: {
    pageChangeHandler(page) {
      this.currentPage = page;
    },
    initDatas() {
      if (!this.dataset.datas.pageConfig) {
        return;
      }
      this.miniResource = this.dataset.datas;
      const parsedJson = JSON.parse(this.dataset.datas.pageConfig);
      this.pageConfigs = parsedJson;
      console.log("this.pageConfigs", this.pageConfigs);
    },
  },
};
</script>

<style lang="less" scoped>
.single-mini-preview {
  width: 375px;
  box-shadow: 1px 1px 2px 0px rgba(142, 142, 142, 0.1);
  border: 1px solid #eef2f6;
  overflow: hidden;
  .graph-border {
    height: 712px;
    width: 100%;
    position: relative;
    background: #f6f8fa;
    margin-bottom: 20px;
    .mini-name-line {
      cursor: default;
      position: absolute;
      top: 60px;
      left: 12px;
      font-weight: 500;
      font-size: 14px;
      color: #30313b;
      .name-span {
        display: inline-block;
        max-width: 140px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        margin: 0 4px;
      }
    }
    .items-container {
      overflow: auto;
      .single-item {
        border: 2px solid #00000000;
        position: relative;
        width: calc(100% - 24px);
        margin: 12px;
        border-radius: 8px;
        cursor: default;
      }
    }
  }
}
</style>